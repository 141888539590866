import { render, staticRenderFns } from "./AdminAdsSection.vue?vue&type=template&id=62956abe&scoped=true&"
import script from "./AdminAdsSection.vue?vue&type=script&lang=js&"
export * from "./AdminAdsSection.vue?vue&type=script&lang=js&"
import style0 from "./AdminAdsSection.vue?vue&type=style&index=0&id=62956abe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62956abe",
  null
  
)

export default component.exports